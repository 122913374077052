import { render, staticRenderFns } from "./customer.html?vue&type=template&id=126ef3d7&scoped=true&external"
import script from "./customer.ts?vue&type=script&lang=ts&external"
export * from "./customer.ts?vue&type=script&lang=ts&external"
import style0 from "./customer.scss?vue&type=style&index=0&id=126ef3d7&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "126ef3d7",
  null
  
)

/* custom blocks */
import block0 from "./customer.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2FsuperAdmin%2Fcustomer%2Fcustomer.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports